export const environment = {
  production: false,
  panel: "matrixexch_",
  domain: "2023exch.com",
  baseUrl: "https://api.2023exch.com",
  // domain: "mango777.club",
  // baseUrl: "https://api.mango777.club",
  trafficTag: '',
  pollers: {
    oddsData: 600,
    listOddsData: 10000,
    scoresData: 1000,
  },
  noDataMsg: 'No Data Found',
  isMobile: true,
  isBetOpenEnv : false,
  stakes: { "stake_name_1": "100", "stake_name_2": "5000", "stake_name_3": "500", "stake_name_4": "25000", "stake_name_5": "50000", "stake_name_6": "100000", "stake_name_7": "500000", "stake_name_8": "1000000", "stake_amount_1": 100, "stake_amount_2": 5000, "stake_amount_3": 500, "stake_amount_4": 25000, "stake_amount_5": 50000, "stake_amount_6": 100000, "stake_amount_7": 500000, "stake_amount_8": 1000000 },
  horseRaceJockeyImgPrefix: 'https://content-cache.cdnbf.net/feeds_images/Horses/SilkColours/',
  desktopStyles: [],
  mobileStyles: [],
  host: {
    domain: '',
    name: '',
    dName: '',
    ext: '',
    theme: '',
    email: '',
    demoLogin: false,
    bonusList: false,
    signUpActive: false,
    fancyEnable: false,
    iframeDomainUrl: '',
    pusher: {
      key: '',
      cluster: ''
    },
    themePath: [''],
  },
  hosts: [
    {
      domain: 'localhost',
      name: 'localhost',
      dName: 'LocalHost',
      ext: '.com',
      theme: 'blue-body',
      email: 'contactmatrixexch9@gmail.com',
      demoLogin: true,
      bonusList: false,
      signUpActive: true,
      fancyEnable: false,
      iframeDomainUrl: 'https://panel.freeplay24.com/',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
        // key: 'c9d022cf8a50a0cd9401',
        // cluster: 'ap2'
      },
      themePath: ['assets/css/theme-blue.css'],
    },
    {
      domain: 'matrixexch99.com',
      name: 'matrixexch99',
      dName: 'Matrixexch99',
      ext: '.com',
      theme: 'blue-body',
      email: 'contactmatrixexch99@gmail.com',
      demoLogin: true,
      bonusList: false,
      signUpActive: false,
      fancyEnable: false,
      iframeDomainUrl: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-blue.css'],
    },
    {
      domain: 'myfairplay.win',
      name: 'myfairplay',
      dName: 'Myfairplay',
      ext: '.win',
      theme: 'blue-body',
      email: 'contactmyfairplay@gmail.com',
      demoLogin: true,
      bonusList: true,
      signUpActive: false,
      fancyEnable: false,
      iframeDomainUrl: '',
      pusher: {
        key: 'f165248f15faf3f3c851',
        cluster: 'ap2'
        // key: 'c9d022cf8a50a0cd9401',
        // cluster: 'ap2'
      },
      themePath: ['assets/css/theme-orange.css'],
    },
    {
      domain: 'rkanna.club',
      name: 'rkanna',
      dName: 'rkanna',
      ext: '.club',
      theme: 'blue-body',
      email: '',
      demoLogin: true,
      bonusList: false,
      signUpActive: false,
      fancyEnable: false,
      iframeDomainUrl: '',
      pusher: {
        key: '5b1dec503fbd6e8b176c',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-blue.css'],
    },
    {
      domain: 'bigbro18.com',
      name: 'bigbro18',
      dName: 'bigbro18',
      ext: '.com',
      theme: 'blue-body',
      email: '',
      demoLogin: true,
      bonusList: false,
      signUpActive: false,
      fancyEnable: false,
      iframeDomainUrl: '',
      pusher: {
        key: '5b1dec503fbd6e8b176c',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-blue.css'],
    },
    {
      domain: 'kings111.com',
      name: 'kings111',
      dName: 'kings111',
      ext: '.com',
      theme: 'blue-body',
      email: '',
      demoLogin: true,
      bonusList: false,
      signUpActive: true,
      fancyEnable: false,
      iframeDomainUrl: 'https://panel.freeplay24.com/',
      pusher: {
        key: '5b1dec503fbd6e8b176c',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-blue.css'],
    },
    {
      domain: 'shiva365.com',
      name: 'shiva365',
      dName: 'shiva365',
      ext: '.com',
      theme: 'blue-body',
      email: '',
      demoLogin: true,
      bonusList: false,
      signUpActive: false,
      fancyEnable: false,
      iframeDomainUrl: 'https://panel.freeplay24.com/',
      pusher: {
        key: '5b1dec503fbd6e8b176c',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-blue.css'],
    },
  ],
};
